import {
    ActionEventAction,
    ElementEventAction,
    EventTrigger,
    EventTriggerFixed,
    ITrackNew,
    Main,
    Provider,
} from 'wikr-core-analytics';

import { changePathNameToScreenId } from '../helpers';

interface TrackClickData {
    elementEvent?: ElementEventAction;
    actionEvent?: ActionEventAction;
    screenIdParam?: string;
    eventLabel?: string | string[];
}

interface CustomDataOptions {
    event_action?: string;
    event_label?: string | number;
}

export const trackScreenLoad = (screenCustomId?: string) => {
    const screenId = screenCustomId ?? changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
    });
};

export const trackEvent = (event: EventTrigger, eventLabel?: string | string[]) => {
    const screenId = changePathNameToScreenId();

    Main.trackNew({
        eventData: { event, screenId },
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};

export const trackEventFixed = (event: EventTriggerFixed, eventLabel?: string | string[]) => {
    Main.trackNew({
        eventData: event,
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};

export const trackClick = ({ elementEvent, actionEvent, screenIdParam, eventLabel }: TrackClickData) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId },
        ...(elementEvent && actionEvent && { actionData: { elementEvent, actionEvent } }),
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};

export const customTrackDecorator = (eventType: string, eventAction?: string, eventLabel?: string | number) => {
    const trackParams: ITrackNew = {
        eventData: eventType,
        actionData: eventAction,
    };

    if (eventLabel) {
        trackParams.data = { event_label: `${eventLabel}` };
    }

    Main.trackNew(trackParams);
};

export const trackClickCustom = (options?: CustomDataOptions, screenIdParam?: string) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    customTrackDecorator(`${screenId}__click`, options?.event_action, options?.event_label);
};

export const clearUserDataFromAnalytics = () => {
    Provider.provideData({
        userId: undefined,
        gender: '',
        country: async () => null,
        abTestName: async () => await null,
    });
};
