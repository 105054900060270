import { Provider } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_ANALYTIC } from 'sentry-utils';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { selectCurrentUser } from 'store/user/selectors';

import sentry from 'services/Sentry/SentryInstance';

import { useSelector } from 'hooks';

export function useAnalytic() {
    const location = useLocation();

    const userData = useSelector(selectCurrentUser);

    const { country, user_id, gender } = userData;

    const clientHandlerFn = (e: unknown) => {
        if (e instanceof Error) {
            sentry.logError(e, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR);
        }
    };

    const getUtmSource = (): string | undefined => localStorage.getItem('utmSource') ?? undefined;

    useEffect(() => {
        gender && localStorage.setItem('gender', gender);
    }, [gender]);

    const setAnalyticData = () => {
        Provider.provideData({
            country: async () => await country,
            abTestName: async () => await null,
            clientHandlerFn,
            utmSource: getUtmSource(),
        });
        user_id && Provider.setUserId(user_id);
    };

    useEffect(() => {
        if (userData) {
            setAnalyticData();
        }
    }, [location?.pathname]);
}
